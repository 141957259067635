import { AxiosError } from 'axios'

/**
 * Extrait un message d'erreur lisible à partir d'une erreur de requête API
 * 
 * @param error L'erreur d'API (souvent une AxiosError)
 * @returns Un objet contenant le message d'erreur et le code de statut HTTP si disponible
 */
export function handleApiError(error: any) {
  // Valeurs par défaut
  let message = 'Une erreur inattendue est survenue'
  let statusCode = 500

  if (error?.isAxiosError) {
    // C'est une erreur Axios
    const axiosError = error as AxiosError<any>
    statusCode = axiosError.response?.status || 500
    
    // Extraction du message d'erreur en fonction de la structure de la réponse
    if (axiosError.response?.data) {
      const data = axiosError.response.data
      
      // Cas d'un message d'erreur unique
      if (typeof data === 'string') {
        message = data
      } 
      // Cas où l'erreur est un objet avec un champ detail
      else if (data.detail) {
        message = data.detail
      }
      // // Cas où l'erreur est dans un champ name (généralement pour les validations)
      // Commenté parce que prend le dessus sur le dernier cas
      // else if (data.name && Array.isArray(data.name) && data.name.length > 0) {
      //   message = data.name[0]
      // }
      // Cas d'un message direct
      else if (data.message) {
        message = data.message
      }
      // Cas des erreurs non liées à un champ spécifique
      else if (data.non_field_errors && Array.isArray(data.non_field_errors) && data.non_field_errors.length > 0) {
        message = data.non_field_errors[0]
      }
      // Cas où l'API renvoie des erreurs pour plusieurs champs
      else if (typeof data === 'object') {
        // Récupération du premier message d'erreur trouvé
        for (const key in data) {
          if (Array.isArray(data[key]) && data[key].length > 0) {
            message = `${key}: ${data[key][0]}`
            break
          } else if (typeof data[key] === 'string') {
            message = `${key}: ${data[key]}`
            break
          }
        }
      }
    } else if (axiosError.message) {
      // Si pas de response.data mais un message d'erreur Axios
      if (axiosError.message === 'Network Error') {
        message = 'Erreur de connexion au serveur'
      } else {
        message = axiosError.message
      }
    }
  } else if (error instanceof Error) {
    // C'est une erreur JavaScript standard
    message = error.message
  } else if (typeof error === 'string') {
    // C'est juste une chaîne de caractères
    message = error
  }

  return { message, statusCode }
}