// stores/competitionStore.ts

import { defineStore } from 'pinia'
import type { Competition, ExtendedCompetition, Judge } from '@/interfaces/competition'

import { CompetitionManager } from '@/utils/competitionManager'

import { CompetitionsService } from '@/services/api/isJudging/competitions'
import { EventsService } from '@/services/api/isJudging/events'
import { TeamsService } from '@/services/api/isJudging/teams'

import notify from '@/services/notify'

import { wampCall, wampControllerCall } from '@/services/wamp'

import { useAuthStore } from './authStore'
import { useJudgementStore } from './judgementStore'

export const useCompetitionStore = defineStore('competitionStore', {
  state: () => ({
    competitions: [] as Competition[] | [],
    activeCompetition: null as CompetitionManager | null,
    loading: false as boolean,
    loadingCompetitions: false as boolean,
    loadingActiveCompetition: false as boolean,
    error: null as null | Error,
    initialized: false as boolean,
    imagePollingInterval: null as number | null,
  }),

  getters: {
    getCompetitions: (state) =>
      state.competitions,
    getActiveCompetition: (state) => state.activeCompetition,
    getTeam: (state) => (teamId: number) => {
      return state.activeCompetition?.getTeam(teamId)
    },
    getScoreDetails: (state) => (flightId: string) => {
      return state.activeCompetition?.getFlightInfo(flightId)?.score_details
    },
    getRoundDraw: (state) => (roundId: number) => {
      return state.activeCompetition?.rawCompetition.events.find((event) => event.rounds.some((round) => round.id === roundId))?.rounds.find((round) => round.id === roundId)?.draw
    },
    hasEventDraw: (state) => (eventId: number) => {
      // Get the event from the active competition
      const event = state.activeCompetition?.rawCompetition.events.find((event) => event.id === eventId)
      if (event && event.rounds && event.rounds.length > 0) {
        for (const round of event.rounds) {
          if (round.draw && Object.keys(round.draw).length > 0) {
            return true
          }
          
          // Vérifier les battles
          if (round.battles && round.battles.length > 0) {
            for (const battle of round.battles) {
              if (battle.draw && Object.keys(battle.draw).length > 0) {
                return true
              }
              
              // Vérifier les sub-battles
              if (battle.sub_battles && battle.sub_battles.length > 0) {
                for (const subBattle of battle.sub_battles) {
                  if (subBattle.draw && Object.keys(subBattle.draw).length > 0) {
                    return true
                  }
                }
              }
            }
          }
        }
      }
      return false
    },
    getEventDraw: (state) => (eventId: number) => {
      const event = state.activeCompetition?.rawCompetition.events.find((event) => event.id === eventId)
      if (event) {
        const draw: Record<any, any> = {}
        event.rounds.sort((a, b) => a.number - b.number).forEach((round) => {
          draw[`round_${round.number}`] = {
            draw: round.draw || []
          }
          if (round.battles && round.battles.length > 0) {
            draw[`round_${round.number}`]['battles'] = {}
            round.battles.forEach((battle) => {
              draw[`round_${round.number}`]['battles'][`battle_${battle.number}`] = {
                draw: battle.draw || []
              }
              if (battle.sub_battles && battle.sub_battles.length > 0) {
                draw[`round_${round.number}`]['battles'][`battle_${battle.number}`]['sub_battles'] = {}
                battle.sub_battles.forEach((subBattle) => {
                  draw[`round_${round.number}`]['battles'][`battle_${battle.number}`]['sub_battles'][`sub_battle_${subBattle.number}`] = {
                    draw: subBattle.draw || []
                  }
                })
              }
            })
          }
        })
        return draw
      }
      return []
    }
  },

  actions: {
    async init() {
      if (this.initialized) {
        return
      }
      this.loading = true
      try {
        this.initialized = true
        this.loading = false
        await this.fetchCompetitions()
      } catch (error: any) {
        console.error("Erreur lors de l'initialisation du Competition Store:", error)
        this.error = error
        this.loading = false
        throw error
      }
    },

    async createCompetition(formData: any, image: any) {
      this.loadingCompetitions = true
      const response = await CompetitionsService.createCompetition(formData, image)
      await this.fetchCompetitions()
      this.loadingCompetitions = false
      return response
    },

    // Update competition information
    async updateCompetition(competitionId: number, data: { name: string, website: string }) {
      try {
        const response = await CompetitionsService.updateCompetition(competitionId, data)
        await this.silentFetchActiveCompetition(competitionId)
        return response
      } catch (error: any) {
        notify('Failed to update competition', error, 'error')
        throw error
      }
    },

    async uploadCompetitionImage(competitionId: number, image: File) {
      try {
        if (!image) {
          throw new Error('No image provided')
        }
        const formData = new FormData()
        formData.append('image', image)
        
        const response = await CompetitionsService.uploadCompetitionImage(competitionId, image)
        
        // Refresh the competition data to get the updated image
        if (this.activeCompetition) {
          const activeCompData = this.activeCompetition.getCompetitionData() as ExtendedCompetition
          if (activeCompData.id === competitionId) {
            // Use the numeric ID for fetching since fetchActiveCompetition expects it
            await this.fetchActiveCompetition(competitionId)
            // Start polling for image processing status
            this.startImageProcessingPolling(competitionId)
          }
        }
        // await this.fetchCompetitions()
        return response
      } catch (error: any) {
        throw error
      }
    },
    
    startImageProcessingPolling(competitionId: number) {
      // Clear any existing polling interval
      this.stopImageProcessingPolling()
      
      // Start polling every 2 seconds
      this.imagePollingInterval = window.setInterval(async () => {
        try {
          // Fetch the latest competition data sans effet de clignotement
          await this.silentFetchActiveCompetition(competitionId)
          
          // Check if we have the active competition and its processing status
          if (this.activeCompetition) {
            const activeCompData = this.activeCompetition.getCompetitionData() as ExtendedCompetition
            
            // If processing is complete or failed, stop polling
            if (activeCompData.image_processing_status === 'completed') {
              // notify('Image processing', 'Image processing completed successfully', 'success')
              this.stopImageProcessingPolling()
            } else if (activeCompData.image_processing_status === 'failed') {
              notify('Image processing', 'Image processing failed', 'error')
              
              this.stopImageProcessingPolling()
            }
          }
        } catch (error) {
          this.stopImageProcessingPolling()
        }
      }, 2000) as unknown as number
    },
    
    stopImageProcessingPolling() {
      if (this.imagePollingInterval !== null) {
        window.clearInterval(this.imagePollingInterval)
        this.imagePollingInterval = null
      }
    },

    async fetchCompetitions() {
      if (useAuthStore().user) {
        this.loadingCompetitions = true

        try {
          const response = await CompetitionsService.getCompetitions()

          let competitions: any[] = []

          // Check if the response has a 'results' property (paginated response)
          if (response && 'results' in response && Array.isArray(response.results)) {
            // If it's a paginated response, use the results array
            competitions = response.results;
          } else if (Array.isArray(response)) {
            competitions = response
          } else {
            this.error = new Error('Invalid competitions data format')
            throw this.error
          }

          const promises = competitions.map(async (competition) => {
            return {
              ...competition,
            } as Competition
          })
  
          const detailedCompetitions = await Promise.all(promises)
          this.competitions = [] as Competition[]
          this.competitions.push(...detailedCompetitions)

        } catch (error: any) {
          this.error = error
          throw error
        } finally {
          this.loadingCompetitions = false
        }
      }
      else {
        notify('Fetching competitions failed', 'You are not logged in', 'error')
      }
    },

    async fetchActiveCompetition(competition_id: number) {
      // const startTime = performance.now()
      this.loadingActiveCompetition = true

      const competitionDetails = this.competitions?.find(
        (comp) => comp.id === competition_id
      )

      try {
        let competition_datas = null
        if (competitionDetails) {
          this.activeCompetition = null

          const response = await CompetitionsService.getCompetition(competitionDetails.id)
          const ij_data = response

          competition_datas = {
            ...ij_data,
          }

          this.activeCompetition = new CompetitionManager(competition_datas)
          this.error = ij_data.error

        } else {
          this.activeCompetition = null
          notify('Fetching competition failed', 'Competition not found', 'error')
        }
      } catch (error: any) {
        throw error
      } finally {
        this.loadingActiveCompetition = false
        // const endTime = performance.now()
        // const executionTime = endTime - startTime
        // console.log(`fetchActiveCompetition execution time: ${executionTime.toFixed(2)}ms`)
      }
    },

    async silentFetchActiveCompetition(competition_id: number) {
      // Cette méthode récupère les données sans réinitialiser l'objet activeCompetition
      // pour éviter l'effet de clignotement dans l'interface
      if (!this.activeCompetition) {
        // Si pas de compétition active, utiliser la méthode standard
        return this.fetchActiveCompetition(competition_id)
      }

      try {
        const competitionDetails = this.competitions?.find(
          (comp) => comp.id === competition_id
        )

        if (competitionDetails) {
          // Récupérer les nouvelles données sans réinitialiser activeCompetition
          const response = await CompetitionsService.getCompetition(competitionDetails.id)
          
          // Mettre à jour l'objet CompetitionManager existant avec les nouvelles données
          if (this.activeCompetition) {
            this.activeCompetition.rawCompetition = {
              ...response
            }
            // Réinitialiser les données internes du CompetitionManager
            this.activeCompetition.reinitializeData(response)
          }
          
          this.error = response.error
        }
      } catch (error: any) {
        // Do nothing
      }
    },

    async refreshCompetitionData() {
      if (!this.activeCompetition) {
        return
      }

      try {
        // Utiliser la méthode silencieuse pour éviter l'effet de clignotement
        await this.silentFetchActiveCompetition(
          this.activeCompetition.getCompetitionData().id
        )
      } catch (error) {
        // notify('Fetching competition failed', 'Server Down ? ', 'error')
        // this.error = error as Error
        // throw error
        // Finalement, on n'affiche pas d'erreur pour ne pas perturber l'utilisateur
      }
    },

    // Create a new event
    async createEvent(competitionId: number, eventData: any) {
      try {
        const disciplineId = Number(eventData.discipline_id)
        const categoryId = Number(eventData.category_id)
        const formatId = Number(eventData.format_id)
        const eventName = eventData.name

        const data = {
          discipline_id: disciplineId,
          category_id: categoryId,
          format_id: formatId,
          name: eventName,
          competition_id: competitionId
        }
        const response = await EventsService.createEvent(data)
        await this.silentFetchActiveCompetition(competitionId)
        return response
      } catch (error: any) {
        throw error
      }
    },

    // Update an event
    async updateEvent(eventId: number, eventData: { 
      name: string, 
      discipline_id: string, 
      category_id: string | null,
      format_id: string | null,
      ffp_providers_ids: number[],
      new_ffp_providers: { id_event: string, id_event_spe: string }[]
    }) {
      try {
        const data = {
          name: eventData.name,
          discipline_id: Number(eventData.discipline_id),
          category_id: Number(eventData.category_id),
          format_id: Number(eventData.format_id),
          ffp_providers_ids: eventData.ffp_providers_ids,
          new_ffp_providers: eventData.new_ffp_providers
        }
        
        const response = await EventsService.updateEvent(eventId, data)
        
        // Refresh the competition data
        if (this.activeCompetition) {
          await this.silentFetchActiveCompetition(this.activeCompetition.rawCompetition.id)
        }
        
        return response
      } catch (error: any) {
        throw error
      }
    },

    // Delete an event
    async deleteEvent(eventId: number) {
      try {
        const response = await EventsService.deleteEvent(eventId)
        // Get the competition ID from the active competition
        if (this.activeCompetition) {
          await this.silentFetchActiveCompetition(this.activeCompetition.rawCompetition.id)
        }
        return response
      } catch (error: any) {
        throw error
      }
    },

    // Create a new draw for an event
    async generateDraw(eventId: number) {
      try {
        const response = await EventsService.generateDraw(eventId)
        if (response) {
          notify('SUCCESS', 'Draw generated successfully', 'success')
          await this.refreshCompetitionData()
          return response
        }
      } catch (error: any) {
        notify('ERROR', 'Error generating draw', 'error')
        throw error
      }
    },

    // Clear a draw for an event
    async clearDraw(eventId: number) {
      try {
        const response = await EventsService.clearDraw(eventId)
        if (response.status === 200) {
          notify('SUCCESS', 'Draw cleared successfully', 'success')
          await this.refreshCompetitionData()
        }
      } catch (error: any) {
        notify('ERROR', 'Error clearing draw', 'error')
        throw error
      }
    },

    // Update a draw for an event
    async updateDraw(eventId: number, data: { round_id?: number, battle_id?: number, draw_refs: string }) {
      try {
        const response = await EventsService.updateDraw(eventId, data)
        if (response.status === 200) {
          await this.refreshCompetitionData()
          return response.data
        }
      } catch (error: any) {
        throw error
      }
    },

    // Create a new team for an event
    async createTeam(competitionId: number, eventId: number, teamData: any) {
      try {
        const response = await TeamsService.createTeam(competitionId, eventId, teamData)
        
        await this.fetchEventTeams(eventId)
        if (this.activeCompetition?.getCompetitionData().id) {
          await this.silentFetchActiveCompetition(this.activeCompetition.getCompetitionData().id)
        }
        return response
      } catch (error: any) {
        throw error
      }
    },

    // Add a team to an event
    async addTeamToEvent(eventId: number, teamId: number) {
      try {
        const response = await TeamsService.addTeamToEvent(eventId, teamId)
        
        await this.fetchEventTeams(eventId)
        if (this.activeCompetition?.getCompetitionData().id) {
          await this.silentFetchActiveCompetition(this.activeCompetition.getCompetitionData().id)
        }
        return response
      } catch (error: any) {
        throw error
      }
    },

    // Remove a team from an event
    async removeTeamFromEvent(eventId: number, teamId: number) {
      try {
        const response = await TeamsService.removeTeamFromEvent(eventId, teamId)

        await this.fetchEventTeams(eventId)
        if (this.activeCompetition?.getCompetitionData().id) {
          // We do't wait for the silentFetchActiveCompetition
          this.silentFetchActiveCompetition(this.activeCompetition.getCompetitionData().id)
        }
        return response
      } catch (error: any) {
        throw error
      }
    },

    // Delete a team
    async deleteTeam(eventId: number | null, teamId: number) {
      try {
        if (this.activeCompetition?.getCompetitionData().id) {
          const response = await TeamsService.deleteTeam(teamId)

          if (eventId) {
            await this.fetchEventTeams(eventId)
          }
          // We do't wait for the silentFetchActiveCompetition
          this.silentFetchActiveCompetition(this.activeCompetition.getCompetitionData().id)
          return response
        } else {
          throw new Error('No active competition')
        }
      } catch (error: any) {
        throw error
      }
    },

    // async fetchCompetitionTeams(competitionId: number) {
    //   try {
    //     const teams = await CompetitionsService.getCompetitionTeams(competitionId)        
    //     return teams
    //   } catch (error: any) {
    //     const { message } = handleApiError(error)
    //     notify('Failed to fetch event teams', message, 'error')
    //     throw error
    //   }
    // },

    async fetchEventTeams(eventId: number) {
      try {
        const teams = await TeamsService.getEventTeams(eventId)
        
        if (this.activeCompetition) {
          const competitionData = this.activeCompetition.getCompetitionData() as ExtendedCompetition
          if (competitionData && competitionData.events) {
            const eventIndex = competitionData.events.findIndex(e => e.id === eventId)
            if (eventIndex !== -1) {
              competitionData.events[eventIndex].teams = teams
            }
          }
        }
        return teams
      } catch (error: any) {
        throw error
      }
    },

    async createFlight(flight_id: string) {
      if (!this.activeCompetition) {
        throw new Error('No active competition')
      }

      const flight = this.activeCompetition.getFlightInfo(flight_id)
      if (!flight) {
        throw new Error('Flight not found')
      }

      const event = (this.activeCompetition
        .getCompetitionData() as ExtendedCompetition)
        .events.find((event) => event.id === flight.event_id)

      if (!event) {
        throw new Error('Event not found')
      }

      const eventJudges = event.judges as Record<
        string,
        { identifier: string }
      >

      const judges: Record<string, string> = {}

      for (let i = 0; i < Object.keys(eventJudges).length; i++) {
        const key = `wm_${i}`
        judges[key] = eventJudges[i].identifier
      }


      // Return ffp_id_event values in an array from ffp_providers of this event
      let id_events = event.ffp_providers?.map((provider: any) => provider.id_event)
      // Return ffp_id_event_spe values in an array from ffp_providers of this event
      let id_event_spes = event.ffp_providers?.map((provider: any) => provider.id_event_spe)
      
      let ffp_id_events = null
      let ffp_id_event_spes = null

      if (id_events && id_events.length > 0) {
        // ffp_id_events is an array of strings with duplicates removed
        ffp_id_events = [...new Set(id_events.map((id) => String(id)))]
      }

      if (id_event_spes && id_event_spes.length > 0) {
        // ffp_id_event_spes is an array of strings with duplicates removed
        ffp_id_event_spes = [...new Set(id_event_spes.map((id) => String(id)))]
      }


      const config = {
        event: {
          is_tunn3l_linked: this.activeCompetition.getCompetitionData().is_tunn3l_linked,
          tunn3l_provider: this.activeCompetition.getCompetitionData().tunn3l_provider,
          competition_id: flight.competition_id,
          event_id: flight.event_id
        },
        round_id: flight.round_id,
        battle_id: flight.battle_id,
        round_number: flight.round_number,
        battle_number: flight.battle_number,
        flight_number: flight.number,
        team: flight.team,
        flight_id: flight_id,
        discipline: flight.discipline,
        flight_type: flight.type,
        judges: judges,
        judges_details: eventJudges,
        draw: flight.draw || [],
        draw_expanded: flight.draw_expanded || [],
        ...flight.configuration, // Ajoute toutes les clés/valeurs de flight.configuration
        ...(flight.configuration.bust_value !== undefined && {
          bust: flight.configuration.bust_value // Ajoute bust si bust_value existe
        }),
        ...(flight.configuration.skip_value !== undefined && {
          skip: flight.configuration.skip_value // Ajoute skip si skip_value existe
        }),
        t3_event_id: flight.t3_event_id || null,
        t3_round_id: flight.t3_round_id || null,
        t3_battle_id: flight.t3_battle_id || null,
        t3_team_id: flight.t3_team_id || null,
        comment: flight.comment || null,
        // If ffp_id_events
        ...(ffp_id_events && {
          ffp_id_events: ffp_id_events
        }),
        // If ffp_id_event_spes
        ...(ffp_id_event_spes && {
          ffp_id_event_spes: ffp_id_event_spes
        })
      }

      // // wampControllerCall('round_' + flight.flight_type, 'create', configuration)
      // wampCall('competition.dist.round.create', [config])
      
      let controller = ''

      switch (flight.discipline.toLowerCase()) {
        case 'fs4':
        case 'fs8':
        case 'vfs4':
          controller = 'relative_work_ng'
          break
        default:
          switch (flight.type.toLowerCase()) {
            case 'speed':
              controller = 'round_speed'
              break
            case 'free':
            case 'compulsory':
              controller = 'round_free'
              break
            default:
              notify('Create flight failed', 'Unknown flight type', 'error')
              throw new Error('Unknown flight type')
          }
      }      
      wampControllerCall(controller, 'create', config)
    },

    async updateFlightScore(flightId: string, score: number) {
      if (Number.isInteger(score)) {
        score = score
      } else {
        score = Number(score.toFixed(3))
      }

      if (!this.activeCompetition) {
        throw new Error('No active competition')
      }
      const flight = this.activeCompetition.getFlightInfo(flightId)
      if (!flight) {
        throw new Error('Flight not found')
      }
      flight.score = score

      // TODO: POST score details to Backend

      // update the score in the active competition rawCompetition
      const event = this.activeCompetition.rawCompetition.events.find((event) => event.id === flight.event_id)
      if (event) {
        const round = event.rounds.find((round) => round.id === flight.round_id)
        if (round) {
          // Explicit type conversion since flight IDs in the array are numbers but flightId parameter is a string
          const foundFlight = round.flights.find((f) => String(f.id) === flightId)
          if (foundFlight) {
            foundFlight.score = score
          }
          else if (round.battles) {
            const battle = round.battles.find((b) => b.id === flight.battle_id)
            if (battle) {
              const foundFlight = battle.flights.find((f) => String(f.id) === flightId)
              if (foundFlight) {
                foundFlight.score = score
              }
            }
          }
        }
      }
    },

    async createFreeBattleJudgement(eventId: number, roundId: number, battleId: number) {
      if (!this.activeCompetition) {
        throw new Error('No active competition')
      }
      const eventJudges = (this.activeCompetition
        .getCompetitionData() as ExtendedCompetition)
        .events.find((event) => event.id === eventId)?.judges

      const teams = (this.activeCompetition
        .getCompetitionData() as ExtendedCompetition)
        .events.find((event) => event.id === eventId)
        ?.rounds.find((round) => round.id === roundId)
        ?.battles.find((battle) => battle.id === battleId)?.teams

      useJudgementStore().createFreeBattleJudgement(eventId, roundId, battleId, eventJudges, teams)
    },
  }
})
