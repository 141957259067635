// services/api/isJudging/competitions.ts
import { axiosInstance, axiosInstanceMultipart } from '@/utils/axios'
import { handleApiError } from '@/utils/errorHandler'


export const EventsService = {

  // Get all disciplines
  async getDisciplines() {
    try {
      const response = await axiosInstance.get('disciplines/')
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },

  // Get all categories
  async getCategories() {
    try {
      const response = await axiosInstance.get('categories/')
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },

  // Get all event formats
  async getEventFormats() {
    try {
      const response = await axiosInstance.get('event-formats/')
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },


  // Create a new event
  async createEvent(data: any) {
    try {
      const response = await axiosInstance.post('events/', data)
      
      // Récupérer les notifications depuis les headers
      const notifications = response.headers['x-notifications']
      
      // Retourner à la fois les données et les notifications
      return {
        ...response.data,
        notifications: notifications ? JSON.parse(notifications) : []
      }
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },

  // Update an event
  async updateEvent(eventId: number, data: any) {
    try {
      const response = await axiosInstance.patch(`events/${eventId}/`, data)
      
      // Récupérer les notifications depuis les headers
      const notifications = response.headers['x-notifications']
      
      // Retourner à la fois les données et les notifications
      return {
        ...response.data,
        notifications: notifications ? JSON.parse(notifications) : []
      }
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },

  // Delete an event
  async deleteEvent(eventId: number) {
    try {
      const response = await axiosInstance.delete(`events/${eventId}/`)
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },

  // Récupérer les FFPProviders
  async getFFPProviders() {
    try {
      const response = await axiosInstance.get('ffp-providers/')
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },

  // Générer un draw pour un événement
  async generateDraw(eventId: number) {
    try {
      const response = await axiosInstance.post(`events/${eventId}/generate-draw/`)
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },
  
  // Effacer le draw existant pour un événement
  async clearDraw(eventId: number) {
    try {
      const response = await axiosInstance.post(`events/${eventId}/clear-draw/`)
      return response
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },
  
  // Mettre à jour le draw d'un round ou d'une battle
  async updateDraw(eventId: number, data: { round_id?: number, battle_id?: number, draw_refs: string }) {
    try {
      const response = await axiosInstance.post(`events/${eventId}/update-draw/`, data)
      return response
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },
}