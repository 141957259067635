// services/api/isJudging/teams.ts
import { axiosInstance } from '@/utils/axios'
import { handleApiError } from '@/utils/errorHandler'

export const TeamsService = {

  // Create a new team
  async createTeam(competitionId: number, eventId: number, teamData: any) {
    try {
      const response = await axiosInstance.post('teams/', {
        ...teamData,
        competition_id: competitionId,
        event_id: eventId
      })
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error);
      throw new Error(message);
    }
  },

  // Remove a team from a competition
  async deleteTeam(teamId: number) {
    try {
      const response = await axiosInstance.delete(`teams/${teamId}/`)
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },

  // Récupérer les équipes d'une compétition
  // async getCompetitionTeams(competitionId: number) {
  //   try {
  //     const response = await axiosInstance.get(`competitions/${competitionId}/teams/`)
  //     return response.data
  //   } catch (error: any) {
  //     const { message } = handleApiError(error)
  //     throw new Error(message)
  //   }
  // },

  // Récupérer les équipes d'un événement
  async getEventTeams(eventId: number) {
    try {
      const response = await axiosInstance.get(`events/${eventId}/teams/`)
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },

  // Ajouter une équipe à un événement
  async addTeamToEvent(eventId: number, teamId: number) {
    try {
      const response = await axiosInstance.post(`teams/${teamId}/add-to-event/${eventId}/`)
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },

  // Supprimer une équipe d'un événement
  async removeTeamFromEvent(eventId: number, teamId: number) {
    try {
      const response = await axiosInstance.delete(`teams/${teamId}/remove-from-event/${eventId}/`)
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },
}