import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home.vue'
import Status from '@/views/Status.vue'

import { useAuthStore } from '@/stores/authStore'
import notify from '@/services/notify'

const routes = [
  {
    path: '/',
    name: 'home',
    // component: Home
    component: () => import('@/views/Competitions.vue'),
  },
  {
    path: '/competitions',
    name: 'competitions',
    component: () => import('@/views/Competitions.vue')
  },
  {
    path: '/training/:discipline?/:type?',
    name: 'training',
    component: () => import('@/views/Training.vue'),
    meta: { navActive: true },
    props: true
  },
  // {
  //   path: '/settings',
  //   name: 'settings',
  //   component: () => import('@/views/Settings.vue')
  // },
  // {
  //   path: '/status',
  //   name: 'status',
  //   component: Status
  // },
  {
    path: '/competition/:competition_id/:event_id?',
    name: 'competition',
    component: () => import('@/components/competition/CompetitionDetail.vue'),
    meta: { requiresAuth: true },
    props: (route: any) => ({
      competition_id: Number(route.params.competition_id),
      event_id: route.params.event_id ? Number(route.params.event_id) : undefined
    })
  },
  // TODO : Page déconnectée de l'appStatus pour les rejudges
  // {
  //   path: '/fs/:round_id/rejudge',
  //   name: 'rejudgeFS',
  //   component: () => import('@/components/formation_skydiving/RejudgeFS.vue'),
  //   meta: { requiresAuth: true },
  //   props: (route: any) => ({
  //     round_id: Number(route.params.round_id)
  //   }),
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/auth/Signup.vue')
  },
  {
    path: '/verify/:uid/:token',
    name: 'verify',
    component: () => import('@/views/auth/Verify.vue'),
    props: true
  },
  {
    path: '/verify-email/:key',
    name: 'verify-email',
    component: () => import('@/views/auth/VerifyEmail.vue'),
    props: true
  },
  {
    path: '/reset-password/:key',
    name: 'reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
    props: true
  },
  {
    path: '/my-account',
    name: 'my-account',
    component: () => import('@/views/MyAccount.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/Privacy.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()
  const isAuthenticated = await authStore.checkAuth()

  // Si l'utilisateur essaie d'accéder à la page de login et est déjà authentifié
  if (to.name === 'login' && isAuthenticated) {
    notify('Vous êtes déjà connecté.', "Redirection vers la page d'accueil.", 'info')
    return next({ name: 'home' })
  }

  // Vérification des routes nécessitant une authentification
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      return next()
    } else {
      notify('Accès refusé', 'Vous devez être connecté pour accéder à cette page.', 'info')
      return next({ name: 'login' })
    }
  }

  // Pour toutes les autres routes
  next()
})

export default router
