// utils/competitionManager.ts
import type {
  Competition,
  ExtendedCompetition,
  Event,
  Round,
  Flight,
  Team,
  Battle,
} from '@/interfaces/competition'

export class CompetitionManager {
  public rawCompetition: ExtendedCompetition
  private teams: Map<number, Team> = new Map()
  private flightMap: Map<string, Flight> = new Map()

  constructor(competition_datas: ExtendedCompetition) {
    this.rawCompetition = competition_datas
    this.initializeData(competition_datas)
    // this.calculateEventsResults(competition_datas)
  }

  private initializeData(competition_datas: ExtendedCompetition) {
    competition_datas.events.forEach((event: Event) => {
      // On alimente la map des teams
      event.teams.forEach((team: Team) => {
        this.teams.set(team.id, team)
      })
      // On alimente la map des flights
      event.rounds.forEach((round: Round) => {
        round.flights.forEach((flight: Flight) => {
          this.addFlight(
            flight,
            event.id,
            round.id,
            null,
            round.number,
            null,
            // Ci dessous a supprimer lorsque l'es appels a t3 seront fait depuis IsJudging
            this.rawCompetition.tunn3l_provider?.t3_competition_id,
            event.t3_event_id || null,
            round.t3_round_id || null,
            null,
            flight.team?.t3_team_id || null,
            // Fin de la suppression
          )
        })
        if (round.battles) {
          this.processBattles(event,round, round.battles, event.id)
        }
      })
    })
  }

  /**
   * Réinitialise les données internes avec les nouvelles données de compétition
   * sans créer une nouvelle instance de CompetitionManager
   * @param competition_datas Nouvelles données de compétition
   */
  public reinitializeData(competition_datas: ExtendedCompetition) {
    // Vider les maps existantes
    this.teams.clear()
    this.flightMap.clear()
    
    // Réinitialiser avec les nouvelles données
    this.initializeData(competition_datas)
  }

  private processSubBattles(
    event: Event,
    round: Round,
    sub_battles: Battle[],
    eventId: number,
  ) {
    sub_battles.forEach((sub_battle) => {
      sub_battle.flights.forEach((flight) => {
        this.addFlight(
          flight,
          eventId,
          round.id,
          sub_battle.id,
          round.number,
          sub_battle.number,
          // Ci dessous a supprimer lorsque l'es appels a t3 seront fait depuis IsJudging
          this.rawCompetition.tunn3l_provider?.t3_competition_id,
          event.t3_event_id || null,
          round.t3_round_id || null,
          sub_battle.t3_battle_id || null,
          flight.team?.t3_team_id || null,
          // Fin de la suppression
        )
      })
    })
  }

  private processBattles(
    event: Event,
    round: Round,
    battles: Battle[],
    eventId: number,
  ) {
    battles.forEach((battle) => {
      battle.flights.forEach((flight) => {
        this.addFlight(
          flight,
          eventId,
          round.id,
          battle.id,
          round.number,
          battle.number,
          // Ci dessous a supprimer lorsque l'es appels a t3 seront fait depuis IsJudging
          this.rawCompetition.tunn3l_provider?.t3_competition_id,
          event.t3_event_id || null,
          round.t3_round_id || null,
          battle.t3_battle_id || null,
          flight.team?.t3_team_id || null,
          // Fin de la suppression
        )
      })
      if (battle.sub_battles) {
        this.processSubBattles(
          event,
          round,
          battle.sub_battles,
          eventId,
        )
      }
    })
  }

  private addFlight(
    flight: Flight,
    eventId: number,
    roundId: number | null,
    battleId: number | null,
    roundNumber: number | null,
    battleNumber: number | null,
    // Ci dessous a supprimer lorsque l'es appels a t3 seront fait depuis IsJudging
    t3_competition_id: number | null | undefined,
    t3_event_id: number | null,
    t3_round_id: number | null,
    t3_battle_id: number | null,
    t3_team_id: number | null,
    // Fin de la suppression
  ) {
    const extendedFlight: Flight = {
      ...flight,
      competition_id: this.getCompetitionData().id,
      event_id: eventId,
      round_id: roundId || null,
      battle_id: battleId || null,
      round_number: roundNumber || null,
      battle_number: battleNumber || null,
      // Ci dessous a supprimer lorsque l'es appels a t3 seront fait depuis IsJudging
      t3_competition_id: t3_competition_id || null,
      t3_event_id: t3_event_id || null,
      t3_round_id: t3_round_id || null,
      t3_battle_id: t3_battle_id || null,
      t3_team_id: flight.team?.t3_team_id || null,
      // Fin de la suppression
    }
    this.flightMap.set(flight.id.toString(), extendedFlight)
  }

  public getNonTournamentRounds(eventId: number): Round[] {
    const event = this.rawCompetition.events.find((e) => e.id === eventId)
    if (!event) return []
    return event.rounds
      .filter((round: Round) => !round.is_tournament)
      .sort((a, b) => a.number - b.number)
  }

  public getTournamentRounds(eventId: number): Round[] {
    const event = this.rawCompetition.events.find((e) => e.id === eventId)
    if (!event) return []
    return event.rounds.filter((round: Round) => round.is_tournament)
  }

  public getCompetitionData(): Competition {
    return {
      ...this.rawCompetition
    }
  }

  public getFlightInfo(flightId: string): Flight | undefined {
    return this.flightMap.get(flightId)
  }

  public getTeam(teamId: number): Team | undefined {
    return this.teams.get(teamId)
  }
}
