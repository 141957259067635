// services/api/isJudging/competitions.ts
import { axiosInstance, axiosInstanceMultipart } from '@/utils/axios'
import { handleApiError } from '@/utils/errorHandler'


export const CompetitionsService = {
  // Get all competitions
  async getCompetitions() {
    try {
      const response = await axiosInstance.get('competitions/')
      if (response.status !== 200) {
        throw new Error('Failed to fetch competitions')
      }
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },

  async getCompetition(id: number) {
    try {
      const response = await axiosInstance.get('competitions/' + id)
      if (response.status !== 200) {
        throw new Error('Failed to fetch competition')
      }
      return response.data
    } catch (error: any) {
      console.log(error)
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },

  async createCompetition(formData: any, image: any) {
    try {
      let response = null
      if (formData.is_t3_comp) {
        response = await axiosInstance.post('competitions/create-with-tunn3l/', 
          {
            auth_token: formData.auth_token,
            url: formData.url,
            t3_competition_token: formData.t3_competition_token
          }
        )
      }
      else {
        response = await axiosInstance.post('competitions/', formData)
      }

      if (response.status !== 201 || !response.data || !response.data.id || !response.data.name) {
        throw new Error('Invalid response from server')
      }

      if (image) {
        try {
          const competition_id = response.data.id
          const competition_image_response = await axiosInstanceMultipart.post('upload-competition-image/' + competition_id + '/', {
            image: image
          })
          if (competition_image_response.status !== 201 && competition_image_response.status !== 200) {
            console.error('Failed to upload competition image')
          }
        } catch (error) {
          console.error('Error uploading competition image:', error)
          // On ne throw pas l'erreur ici car l'upload de l'image n'est pas critique
        }
      }
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },

  async uploadCompetitionImage(competitionId: number, image: File) {
    try {
      const formData = new FormData()
      formData.append('image', image)
      const response = await axiosInstanceMultipart.post(`upload-competition-image/${competitionId}/`, formData)
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },

  // Update a competition
  async updateCompetition(competitionId: number, data: { name: string, website: string }) {
    try {
      const response = await axiosInstance.patch(`/competitions/${competitionId}/`, data)
      return response.data
    } catch (error: any) {
      const { message } = handleApiError(error)
      throw new Error(message)
    }
  },
}